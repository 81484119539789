import Alpine from "alpinejs";
Alpine.prefix('data-x-');
Alpine.data("main", () => ({

    /*navigation*/
    menuOpened: false,
    mobileMenuOpened: false,
    mobileMenu0: false,
    mobileMenu1: false,
    mobileMenu2: false,
    mobileMenu3: false,
    mobileSearchOpened: false,
    mobileAutoHeight: false,
    mobileMenuSlideLeft: false,
    stickyNavTop: false,
    toggle(menuClass) {
        this.menuOpened = menuClass;
    },
    resetMobileMenu() {
        this.mobileMenuOpened = false;
        this.mobileMenu0 = false;
        this.mobileMenu1 = false;
        this.mobileMenu2 = false;
        this.mobileMenu3 = false;
        this.mobileSearchOpened = false;
    },

    fixedBody: false,

    /*cookies*/
    setCookie(name, value, days) {
        let expires;
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 12 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }
        document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
    },
    getCookie(name) {
        const nameEQ = encodeURIComponent(name) + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0)
                return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
        return null;

    },
}));