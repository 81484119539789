import "../../css/pages/home.scss";

import "../modules/main"

import Alpine from "alpinejs";
import intersect from '@alpinejs/intersect'

Alpine.plugin(intersect);
Alpine.prefix('data-x-');
Alpine.start();
